// @ts-ignore
import React, {useEffect, useState} from "react";
// @ts-ignore
import InputModal from '../../common/InputModal.tsx';
import {DynamicField} from "../../../types";



type ProductFiltersProps = {
    dynamicFields: DynamicField[]
    onFilterSubmit: (filters: Record<string, any>) => void;
}

const ProductFiltersModal: React.FC<ProductFiltersProps> = ({onFilterSubmit, dynamicFields}) => {
    const [filterValues, setFilterValues] = useState({});
    const [filterErrors, setFilterErrors] = useState({});

    useEffect(() => {
        let initialFilterValues = {
            min_price: "",
            max_price: "",
            brand_name: "",
            pet_types: ""
        }

        let initialFilterErrors = {}

        dynamicFields.forEach((item) => {
            if (item.value) {
                initialFilterValues[item.key] = item.value
            }
            initialFilterErrors[item.key] = ""
        })

        setFilterValues(initialFilterValues)
        setFilterErrors(initialFilterErrors)
    }, [dynamicFields])

    return  <InputModal id={"productFilters"}
                        popupTitle={"Product Filters"}
                        buttonTitle={<>
                            <div className={"d-flex flex-row justify-content-between align-middle"}>
                                <span>
                                    Filters
                                </span>
                                <div className={"mx-1 mt-0"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                         className="bi bi-filter" viewBox="0 0 16 16">
                                        <path
                                            d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                    </svg>
                                </div>
                            </div>
                        </>}
                        fields={dynamicFields}
                        fieldValues={filterValues}
                        fieldErrors={filterErrors}
                        onChange={(name: string, value: any) => {
                            setFilterValues({...filterValues, [name]: value});
                        }}
                        onSubmit={() => {
                            onFilterSubmit(filterValues)
                        }}

    />
}

export default ProductFiltersModal;