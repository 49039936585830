const openBootstrapModal = (id: string) => {
    const modalElement = document.getElementById(id);
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
        modalInstance.show()
    }
}

const clearInputsInContainerID = (id: string) => {
    const div = document.getElementById(id);
    if (div) {
        // Reset all input, textarea, and select elements
        div.querySelectorAll("input, textarea").forEach((element) => {
            if (element.type === "checkbox" || element.type === "radio") {
                element.checked = false;
            } else {
                element.value = "";
            }
        });
    }
}

export {
    clearInputsInContainerID,
    openBootstrapModal
}