// @ts-ignore
import React, {useEffect} from 'react';
import {DynamicField} from "../../types";
import DynamicInput from "../DynamicInput";

type InputModalProps = {
    id: string;
    buttonTitle: string;
    popupTitle: string;
    fields: DynamicField[];
    fieldValues: Record<string, number | string>;
    fieldErrors: Record<string, string>;
    onChange: (name: string, value: string | number) => void;
    onSubmit: () => void
    extras?: Record<string, any>
}

const InputModal: React.FC<InputModalProps> = ({id, buttonTitle, popupTitle, fields, fieldValues, fieldErrors, onChange, onSubmit}) => {

    return <div>
        <Popup id={id} popupTitle={popupTitle} buttonTitle={buttonTitle} onSubmit={onSubmit}>
            {fields.map((field, index) => (
                <DynamicInput
                    key={index + "-input"}
                    fieldData={field}
                    fieldValue={fieldValues[field.key] || ''}
                    handleChange={onChange}
                    handleAttachmentChange={onChange}
                    containerClass={field.extras?.container_class || "col-12 mb-3"}
                    errorMessage={fieldErrors[field.key] !== undefined ? fieldErrors[field.key] : ""}
                />
            ))}
        </Popup>
    </div>
}


export default InputModal;

type PopupProps = {
    id: string;
    buttonTitle: string;
    popupTitle: string;
    onSubmit: () => void;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({id, popupTitle, buttonTitle, onSubmit, children}) => {
    const closePopup = () => {
        const modalElement = document.getElementById(id);
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    };

    window.onkeydown = function (event) {
        if (event.keyCode === 27) {
            closePopup();
        }
    };

    return (
        <div>
            <button type="button" className="btn btn-primary px-4" data-bs-toggle="modal" data-bs-target={"#" + id}>
                {buttonTitle}
            </button>

            <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id + "Label"} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={id + "Label"}>{popupTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{textAlign: "left"}}>
                            <div className="form-renders">
                                <div className="inputs-container bg-white mb-2 p-3">
                                    <div className="product-info form row">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex flex-row gap-2">
                            <button type="button"
                                    className="btn btn-secondary flex-grow-1"
                                    data-bs-dismiss="modal">Close
                            </button>
                            <button type="button"
                                    className="btn btn-primary flex-grow-1"
                                    onClick={() => {
                                        onSubmit();
                                        closePopup();
                                    }}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}